
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/common/authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hide = true;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  currentUser: any;
  loginMessage: any;
  displayServiceMessage: boolean = false;
  displayMessageType: any;
  displayMessageText: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (sessionStorage.getItem('loginMessage')) {
      this.loginMessage = JSON.parse(sessionStorage.getItem('loginMessage'));
      this.checkIfLoggedIn();
    }

  }
  async checkIfLoggedIn() {
    if (this.currentUser) {
      this.router.navigate(['Dashboard'])
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });


    if (this.loginMessage && this.loginMessage.displayMessage) {
      this.displayMessage(this.loginMessage.message, 'login-message-info')
    }
    sessionStorage.removeItem('loginMessage');
  }
  ngAfterViewInit() {
    this.loginForm.reset();

  }



  get field() {
    return this.loginForm.controls;
  }

  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    sessionStorage.removeItem('redirectUrl');
    this.authenticationService.login({
      UserName: this.field.username.value,
      Password: this.field.password.value
    }, this.returnUrl).
      subscribe(res => {
        if (res && res.accessToken) {
          var navUrl = (!res.action || res.action === 'index') ? '/index.html' : res.action;
          this.loading = false;
          window.location.href = "/index.html";
        } else {
          this.loading = false;
          this.displayMessage(res.error, 'login-message-error');
        }
      },
        err => {
          this.displayMessage("The username and password entered do not match.", 'login-message-error');
          this.loading = false;
        }
    )
    sessionStorage.clear();
  }
  displayMessage(message, type) {
    this.displayServiceMessage = true;
    this.displayMessageType = type;
    this.displayMessageText = message;
    setTimeout(() => {
      this.displayServiceMessage = false;
    }, 15000);
  }
}
