import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUser: BehaviorSubject<any>;
  contentHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  constructor(private http: HttpClient, private router: Router) {
  }

  login(loginData: object, url: string) {
    return this.http.post<any>(`/api/auth/accessToken`, loginData);
  }
}
