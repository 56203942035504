<div class="container">
  <div class="login-Page-Container">
    <h2 class="login-Page-Header">Sign In</h2>
    <div class="login-Page-Form-Container">
      <mat-progress-bar *ngIf='loading' mode="indeterminate"></mat-progress-bar>
      <div class="login-form">
        <div class="row ">
          <div class="col-xs-12 col-sm-12 ">
            <div class="errorContainer">
              <div *ngIf='displayServiceMessage' class="{{displayMessageType}}">{{displayMessageText}}</div>
            </div>
            <form [formGroup]="loginForm" (keyup.enter)="onSubmit()" autocomplete="off">

              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label class="placeholder-label">Username</mat-label>
                  <input id="username" matInput placeholder="Username" formControlName="username" autofocus>
                  <mat-error *ngIf="submitted && field.username.errors?.required">Username is required</mat-error>
                  <mat-error *ngIf="submitted && field.username.errors?.email">Username is not a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label class="placeholder-label">Password</mat-label>
                  <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                  <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="submitted &&  field.password.errors?.required">Password is required</mat-error>

                </mat-form-field>
              </div>

              <div class="form-group">
                <button [disabled]="loading" type="button" (click)="onSubmit()" mat-raised-button color="primary"
                        class="login-page-button-full">
                  Sign In
                </button>
              </div>
              <!-- 
              <div class="form-group">
                <a routerLink="/ForgotPassword" class="btn btn-link ForgotPasswordLink ">Forgot Password?</a>

              </div>
              -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
